import React from 'react';
import * as PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Seo from '../../components/SEO';
import Theme from '../../components/Theme';
import ArticleSection from '../../components/ArticleSection';
import Header from '../../components/Archive/Header';
import Newsletter from '../../components/Newsletter';
import Pagination from '../../components/Pagination';
import GeneralLayout from '../../layouts/index';

const TagTemplate = ({ transition, data, pageContext }) => {
  const { group, index, pageCount, pathPrefix } = pageContext;
  const { contentfulTag, contentfulNewsletter, site } = data;
  const { name, featured } = contentfulTag;

  return (
      <GeneralLayout>
        <Theme theme="blue" transition={transition}>
          <Seo
            siteData={site}
            seoData={contentfulTag.seo}
            defaultTitle={`Emergence - ${name}`}
            pathName={`/${name}`}
          />
          <Header overline="Tag" title={name} featuredArticle={featured} />
          <ArticleSection articles={group} largeArticle={featured ? true : false} />
          {pageCount > 1 && <Pagination index={index} pageCount={pageCount} pathPrefix={pathPrefix} />}
          <Newsletter content={contentfulNewsletter} />
        </Theme>
      </GeneralLayout>
  );
};

TagTemplate.propTypes = {
  transition: PropTypes.object,
  data: PropTypes.object.isRequired,
};

export default TagTemplate;

export const pageQuery = graphql`
  query tagQuery($id: String!) {
    contentfulTag(id: { eq: $id }) {
      ...TagArchiveHeaderQuery
      seo {
        customTitle
        metaDescription
        shareImage {
          file {
            url
          }
        }
      }
    }
    ...NewsletterQuery
    ...SiteQuery
  }
`;
